
/* main.css code required for player */
a,
button {
  color: inherit;
  outline: none;
  border: none;
  background: transparent;
}
.loader {
    border: 16px solid #f3f3f3; /* Light grey */
    border-top: 16px solid #3498db; /* Blue */
    border-radius: 50%;
    width: 50px;
    height: 50px;
    animation: spin 2s linear infinite;
  }
  
  @keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
  }
button:hover {
  cursor: pointer;
}

button:focus {
  outline: 0;
}

.text-main{
    color: #35C0B4 !important;
}
.text-main svg {
    fill: #35C0B4;
}
/* keyboard.ui-widget */
.ui-widget .ui-widget {
    font-size: 1em;
}
  
.ui-widget.ui-widget-content {
    border: 1px solid #c5c5c5;
}
  
.ui-widget-content {
    border: 1px solid #ddd;
    background: #fff;
    color: #333;
}
  
.ui-widget-content a{
    color: #333;
}
.ui-widget-content button{
    border-radius: 3px;
}
.ui-button, .ui-state-default, .ui-widget-content .ui-state-default {
    border: 1px solid #c5c5c5;
    background: #f6f6f6;
    font-weight: 400;
    color: #454545;
}

  
/* end main.css */

/* keyboard */
@font-face {
    font-family: 'SignalFont'; /* Give your font a name */
    src: url('https://tamkin.app/assets/tamkin_website/player/signal_font.TTF') format('truetype'); /* Specify the path to your font file */
}
/* .ui-keyboard .draggable{ */
.ui-keyboard{
    cursor: move;
    padding-top: 10px!important;
    direction: ltr;
}
.ui-keyboard .ui-state-active2{
    background-color: #e6e6e6;
}
.ui-keyboard .ui-keyboard-cancel{
    position: absolute;
    right: 0;
    top: 0;
    background: none;
    border: none;
    color: grey;
    transition: color .3s ease-in;
}
.ui-keyboard .ui-keyboard-cancel:hover{
    color: #3D3D3D;
}
.ui-keyboard .ui-keyboard-cancel sub,
.ui-keyboard .ui-keyboard-bksp sub,
.ui-keyboard .ui-keyboard-tab sub,
.ui-keyboard .ui-keyboard-enter sub,
.ui-keyboard .ui-keyboard-shift sub{
    display: none;
}
.ui-keyboard .ui-keyboard-empty{
    visibility: hidden; 
    user-select: none;
}
@media(max-width:900px) {
    .ui-keyboard .ui-keyboard-empty{
        display: none;
    }

}
@media(min-width:900px) {
    /* end some button sizes */
    .ui-keyboard .ui-keyboard-alt{
        min-width: 5em;
    }
    .ui-keyboard .ui-keyboard-tab{
        min-width: 4em;
    }
    .ui-keyboard .ui-keyboard-shift{
        min-width: 6em;
    }
    .ui-keyboard .ui-keyboard-bksp{
        min-width: 4.4em;
    }
    .ui-keyboard .ui-keyboard-space{
        min-width: calc(100% - 13.6em);
    }
    
    .ui-keyboard .ui-keyboard-keyset{
        text-align: initial;
    }
    .ui-keyboard .ui-keyboard-empty{
        min-width: 4.25em;
    }
    .ui-keyboard{
        padding: 10px!important;
    }
    /* end some button sizes */
}

@media(min-width:700px) {
    .ui-keyboard button{
        font-size: 21px;
        margin: .15em;
        min-width: 2.4em;
        min-height: 2.2em;
    }
    .ui-keyboard button sub{
        font-size: 15px;
    }
}
.ui-keyboard button span{
    font-family: 'SignalFont', sans-serif;
    margin: 0 3px;
}
.ui-keyboard-input-current{
    font-family: 'SignalFont', sans-serif;
}
.ui-keyboard button sub{
    bottom: -0.4em;
    margin: 0 3px;
}

.tp-hero-talk {
    /* position: absolute; */
    position: fixed;
    right: 0;
    top: 50%;
    background-image: url('https://tamkin.app/assets/tamkin_website/img/player/bg-icon-hero.png');
    background-size: 100% 100%;
    width: 48px;
    height: 48px;
    background-position: center;
    background-repeat: no-repeat;
    display: flex;
    cursor: pointer;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    z-index: 10;
}

.tp-hero-talk-text {
    background-image: url('https://tamkin.app/assets/tamkin_website/img/player/bg-icon-text.png');
    font-size: 16px;
    color: #fff;
    text-transform: uppercase;
    /* position: absolute; */
    position: fixed;
    right: -1000px;
    top: 50%;
    height: 48px;
    background-repeat: no-repeat;
    background-position: center;
    background-size: 100% 100%;
    transition: right 0.5s ease-in-out;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1;
    padding: 0 9px;
    border-radius: 4px;
}
.tp-hero-talk-text.active {
    right: 50px;
}



#player-card {
    position: fixed;
    right: -1000px;
    /* width: 357px; */
    /* height: 488px; */
    width: 250px;
    height: 400px;
    transition: right 0.7s ease-out;
    border-radius: 30px 30px 0px 0px;
    background-color: #F6F6F6;
    top: 20%;
    z-index: 9999;
    /* display: none; */
}

#player-card.player-active {
    right: 0;
}
/* always ltr content for icons and buttons */
#player-card .btns-action, 
#player-card .btns-info, 
#player-card .btns-config,
#player-card .settings-card,
#player-card .tutorial-card
{
    direction: ltr;
    text-align: left;
}
/* for top left icon */
#player-card .about-card{
    direction: ltr;
}

#player-card .icon-close {
    position: absolute;
    right: 25px;
    z-index: 1000;
    top: 25px;
    cursor: pointer;
}

#player-card .talk-content {
    position: relative;
    text-align: center;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.animated {
    /* Animation styles */
    animation-name: myAnimation;
    animation-duration: 1s;
    /* Additional animation properties */
}

@keyframes myAnimation {

    /* Keyframe animation styles */
    0% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}

#player-card .talk-content .tutorial-card {
    /* width: 207px; */
    /* height: 103px; */
    /* padding: 12px 37px 12px 37px; */
    padding: 15px;
    border-radius: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 30px;
    position: absolute;
    bottom: 90px;
    background-color: #E7E7E7;
}

#player-card .talk-content .settings-card {
    /* height: 103px; */
    /* padding: 12px 37px 12px 37px; */
    padding: 15px;
    border-radius: 30px;
    display: flex;
    align-items: center;
    /* justify-content: center; */
    justify-content: space-between;
    left: 10px;
    right: 10px;
    /* gap: 30px; */
    gap: 3px;
    position: absolute;
    bottom: 90px;
    background-color: #E7E7E7;
}

#player-card .tutorial-card .translate-item .icon,
#player-card .talk-content .settings-card .icon {
    width: 38px;
    height: 38px;
    background-color: #fff;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
}

#player-card .tutorial-card .translate-item {
    font-size: 12px;
    font-weight: 400;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 3px;
    line-height: 17px;
    color: #1E1E1E;
    cursor: pointer;
}

#player-card .settings-card .settings-item {
    font-size: 12px;
    font-weight: 400;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 3px;
    line-height: 17px;
    color: #1E1E1E;
    cursor: pointer;
}

#player-card .about-card {
    padding: 30px;
    height: 100%;
    position: absolute;
    border-radius: 30px 30px 0px 0px;
    background-color: #F6F6F6;
    /* not inherit because if player is transparent it always inherits that */
    /* background-color: inherit; */
    top: 0;
    left: 0;
    right: 0;
    z-index: 1000;
}

#player-card .about-card .btns-about-bottom {
    /* position: absolute;
    bottom: 40px;
    left: 20px;
    right: 20px; */
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 10px;
}

#player-card .about-card .btns-about-bottom .btn {
    /* height: 56px; */
    border-radius: 16px;
    display: flex;
    font-size: 16px;
    font-weight: 400;
    line-height: 27px;
    align-items: center;
    justify-content: center;
    gap: 8px;
    width: 100%;
}
#player-card .about-card .btns-about-bottom.menu-trans .btn img {
    max-height: 15px;
}
#player-card .about-card .btns-about-bottom.asl-link .btn img {
    max-height: 30px;
}

#player-card .about-card .btns-about-bottom .btn.btn-bg {
    background-color: #2DB9B0;
    color: #fff;
}

#player-card .about-card .btns-about-bottom .btn.btn-border {
    background-color: transparent;
    color: #2DB9B0;
    border: 1px solid #2DB9B0;
}

#player-card .about-card h3 {
    font-size: 24px;
    font-weight: 600;
    color: #1E1E1E;
    margin-bottom: 1em;
    line-height: 29px;
}

#player-card .about-card p {
    font-size: 20px;
    font-weight: 400;
    line-height: 24px;
    color: #1E1E1E;
}

#player-card .about-card .icon {
    width: 48px;
    height: 48px;
    background-color: #F0F5FA;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 1em;
    border-radius: 50%;
    position: relative;
    top: -13px;
    left: -13px;
}

#player-card .about-card .icon img {
    width: auto;
    height: 34px;
}
#player-card .talk-content .change-speed-card {
    /* width: 164px; */
    /* height: 85px; */
    background-color: #E7E7E7;
    border-radius: 30px;
    position: absolute;
    bottom: 70px;
    font-size: 15px;
    padding: 12px 20px;
    font-weight: 400;
    line-height: 20px;
    text-align: center;
    color: #1E1E1E;
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    visibility: hidden;
    z-index: 1;
}

#player-card .talk-content .change-background-card {
    background-color: #E7E7E7;
    border-radius: 30px;
    color: #1E1E1E;
    font-size: 15px;
    display: flex;
    visibility: hidden;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-weight: 400;
    line-height: 30px;
    /* width: 164px; */
    /* padding: 20px 20px; */
    padding: 12px 20px;
    position: absolute;
    bottom: 200px;
}

#player-card .talk-content .man-container {
    padding: 10px;
}

#player-card .talk-content .man-container .img-man {
    /* width: 308px;
    height: 457px; */
    max-width: 100%;
    max-height: 100%;
}

#player-card .talk-content .btns-action {
    position: absolute;
    bottom: 0;
    background-color: rgba(231, 231, 231, 1);
    /* height: 80px; */
    padding: 12px;
    border-radius: 30px 30px 0px 0px;
    width: 100%;
    align-items: center;
    justify-content: space-between;
    display: flex;
}

#player-card .talk-content .btns-action.active,
#player-card .talk-content .btns-config.active,
#player-card .talk-content .btns-info.active {
    border: 2px solid #FF9C00;
    animation: pulse 2s infinite;
    box-shadow: 0 0 0 rgba(255, 156, 0, 0.4);
}

#player-card .talk-content .btns-action.btns-action-loader {
    display: flex;
    justify-content: center;
}

#player-card .talk-content .btns-action.btns-action-loader img {
    width: auto;
    height: 100px;
}

/* .text-transplate.underline {
    text-decoration: underline;
    text-underline-offset: 10px;
    text-decoration-color: inherit;
    text-decoration-thickness: 1.5px;
}

.text-transplate.underline span {
    text-decoration-color: inherit;
    text-underline-offset: 10px;
    text-decoration-color: inherit;
    text-decoration-thickness: 1.5px;
} */

.icon-translate-done {
    position: absolute;
    right: 64px;
    bottom: -25px;
    z-index: 1000;
    display: none;
    width: 56px;
    height: 56px;
}

@-webkit-keyframes pulse {
    0% {
        -webkit-box-shadow: 0 0 0 0 rgba(255, 156, 0, 0.4);
    }

    70% {
        -webkit-box-shadow: 0 0 0 10px rgba(204, 169, 44, 0);
    }

    100% {
        -webkit-box-shadow: 0 0 0 0 rgba(204, 169, 44, 0);
    }
}

@keyframes pulse {
    0% {
        -moz-box-shadow: 0 0 0 0 rgba(255, 156, 0, 0.4);
        box-shadow: 0 0 0 0 rgba(255, 156, 0, 0.4);
    }

    70% {
        -moz-box-shadow: 0 0 0 10px rgba(204, 169, 44, 0);
        box-shadow: 0 0 0 10px rgba(204, 169, 44, 0);
    }

    100% {
        -moz-box-shadow: 0 0 0 0 rgba(204, 169, 44, 0);
        box-shadow: 0 0 0 0 rgba(204, 169, 44, 0);
    }
}

#player-card .talk-content .btns-action .btn-rate-translate img {
    width: 23.17px;
    height: 19.86px;
}

#player-card .unclickable {
    pointer-events: none;
    /* Disables the click event */
}
#player-card #links-container.active .menu-link {
    pointer-events: none;
}
#player-card .talk-content .btns-action span {
    /* width: 48px; */
    /* height: 48px; */
    width: 38px;
    height: 38px;
    /* font-size: 18px; */
    font-size: 16px;
    font-weight: 700;
    line-height: 22px;
    text-align: center;
    border-radius: 50%;
    background-color: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    color: #35C0B4;
}

#player-card .talk-content .btns-action span.active,
#player-card .tut-active {
    border: 2px solid #FF9C00;
    animation: pulse 2s infinite;
    box-shadow: 0 0 0 rgba(255, 156, 0, 0.4);
}
#player-card .tut-active {
    border-radius: 50%;
}


#player-card #loader-hand {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    border-radius: 30px 30px 0px 0px;
    justify-content: center;
    position: absolute;
    background-color: #fff;
    z-index: 10;
    border: 1px solid #eee;
}

#player-card .when-empty-card {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    border-radius: 30px 30px 0px 0px;
    justify-content: center;
    position: absolute;
    background-color: #fff;
    border: 1px solid #eee;
    z-index: 100;
    padding: 10px;
}

#player-card #loader-finish-rate {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    border-radius: 30px 30px 0px 0px;
    justify-content: center;
    position: absolute;
    /* background-color: #fff; */
    background-color: #F6F6F6;
    z-index: 10000;
    top: 0;
    right: 0;
    left: 0;
}

#player-card #loader-finish-rate img {
    width: 100%;
    height: 100%;
}

/* #finish-card{
    height: 100%;
    position: absolute;
    top: 0;
    background-color: red;
  } */
  #player-card #loader-get-translation {
    left: 0;
    bottom: 0;
    /* background-color: #e7e7e7; */
    background-color: inherit;
    position: absolute;
    width: 100%;
    border-radius: 30px 30px 0px 0px;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}
#player-card #loader-get-translation lottie-player {
    transform: scale(1.8);
}
#player-card #loader-get-translation img {
    width: 100px;
    height: 60px;
}

#player-card #finish-rate {
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 30px 30px 0px 0px;
    background-color: #F6F6F6;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
}

#player-card #finish-rate p {
    margin-bottom: 0;
    color: #2DB9B0;
    font-size: 24px;
    font-weight: 600;
    line-height: 29px;
    text-align: center;
}

#player-card #tutorial-menu {
    height: 334px;
    width: 266.28px;
}

#player-card #tutorial-menu h3 {
    font-size: 18px;
    font-weight: 400;
    line-height: 22px;
    /* color: #3F3F4B; */
    padding: 50px 0 0;
    margin-bottom: 20px;
}

#player-card #tutorial-menu .btns-about-bottom {
    position: absolute;
    bottom: 40px;
    left: 20px;
    right: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 10px;
}

#player-card #tutorial-menu .btns-about-bottom .btn {
    /* height: 56px; */
    border-radius: 16px;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 8px;
    width: 100%;
    background-color: #2DB9B0;
    color: #fff;
    /* font-family: Lato; */
    font-size: 16px;
    font-weight: 400;
    line-height: 19px;
}

.btns-config, .btns-info {
    position: absolute;
    bottom: 0;
    background-color: rgba(231, 231, 231, 1);
    /* height: 80px; */
    padding: 12px;
    border-radius: 30px 30px 0px 0px;
    width: 80%;
    align-items: center;
    justify-content: space-between;
    display: flex;
}
.btns-info {
    width: 50%;
}

.btns-config span, .btns-info span {
    /* width: 48px; */
    /* height: 48px; */
    width: 38px;
    height: 38px;
    /* font-size: 18px; */
    font-size: 16px;
    font-weight: 700;
    line-height: 22px;
    text-align: center;
    border-radius: 50%;
    background-color: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    color: #35C0B4;
}

.btns-config span.active, .btns-info span.active {
    border: 2px solid #FF9C00;
    animation: pulse 2s infinite;
    box-shadow: 0 0 0 rgba(255, 156, 0, 0.4);
}

#player-card #rate-translate-content .check-rate {
    display: flex;
    justify-content: space-between;
    /* margin: 3em 4em; */
}

#player-card #rate-translate-content .check-rate .item {
    cursor: pointer;
}

#player-card #rate-translate-content .check-rate .item img {
    width: auto;
    height: 30px;
    margin-bottom: 15px;
}

#player-card #rate-translate-content .check-rate .item img.rate-hover {
    display: none;
}

#player-card #rate-translate-content .check-rate .item p {
    font-size: 16px;
    font-weight: 400;
    margin-bottom: 0;
    /* color: #1E1E1E; */
    line-height: 19px;
}

#player-card #rate-translate-content .check-rate .item .btn-check:checked+.btn {
    border: none;
    outline: none;
    box-shadow: none;
}

#player-card #close-translate,
#player-card #close-settings {
    display: none;
}
#player-card.hide {
    display: none;
}
#player-card .hide {
    display: none !important;
}
#player-card .just-a-placeholder {
    display: none!important;
}
#player-card #icon-rate-after {
    display: none;
}
#player-card #rate-translate-content .check-rate .item .btn-check:checked+.btn .rate-un {
    display: none;
}
#player-card #rate-translate-content .check-rate .item:hover .rate-un {
    display: none;
}
#player-card #warning-menu {
    display: none;
}
#player-card #warning-menu.active {
    display: block;
}

#player-card #rate-translate-content .check-rate .item .btn-check:checked+.btn .rate-hover {
    display: block;
}

#player-card #rate-translate-content .check-rate .item:hover .rate-hover {
    display: block;
}


#player-card #rate-translate-content .rate-translate .btn-bg {
    background-color: #A6A6A6;
    /* height: 56px; */
    color: #fff;
    font-size: 18px;
    font-weight: 400;
    line-height: 24px;
    border-radius: 16px;
    /* position: absolute;
    left: 20px;
    bottom: 40px;
    right: 20px; */
    width: 100%;
}

#player-card #rate-translate-content .rate-translate .btn-bg.btn-confirm {
    background-color: #32BAB1;
}
/* #player-card .when-empty-card img {
    -webkit-animation: object_one 1.5s infinite;
    animation: object_one 1.5s infinite;
  } */
#player-card .when-empty-card .text-develop-container a {
    text-decoration: underline;
}
#player-card .when-empty-card .text-develop {
    /* position: absolute; */
    /* bottom: 30px; */
    display: flex;
    align-items: center;
    gap: 10px;
    font-size: 16px;
    color: #3D3D3D;
    font-weight: 400;
    line-height: 19px;
    text-decoration: underline;
}
#player-card .menu-link:hover,
#player-card .settings-item .icon:hover,
#player-card .translate-item .icon:hover {
    border: 1px solid #2ABAAE;
}

/* side menu card */

#player-card .side-menu-card {
    box-shadow: 0px 2px 4px 0px rgba(63, 103, 173, 0.12);
    border: 1px solid rgba(244, 245, 247, 1);
    border-radius: 16px;
    padding: 20px;
    background-color: #F6F6F6;
    color: #3F3F4B;
    position: fixed;
    right: -1000px;
    width: 257px;
    /* height: 250px; */
    transition: right 0.7s ease-out;
    border-radius: 16px;
    top: 30%;
    z-index: 9999;
    padding: 20px;
}
#player-card .side-menu-card.active {
    right: 260px;
}
#player-card .side-menu-card::before {
    content: "";
    position: absolute;
    top: 30px;
    right: -7px;
    z-index: initial;
    width: 21px;
    height: 17px;
    transform: rotate(126deg) skew(351deg, 4deg);
    border-top: 20px solid #F6F6F6;
    border-right: 20px solid #F6F6F6;
    background-color: #F6F6F6;
    border-radius: 0;
}
#player-card.contrast-style .side-menu-card::before {
    border-top: 20px solid #777;
    border-right: 20px solid #777;
    background-color: #777;
}
#player-card .side-menu-card .icon-close-side {
    position: absolute;
    right: 20px;
    top: 20px;
    cursor: pointer;
}

#player-card .side-menu-card .side-menu-content {
    padding: 50px 0 66px;
}

#player-card .side-menu-card h3 {
    color: inherit;
    font-size: 20px;
    font-weight: 600;
    margin-bottom: 15px;
    line-height: 24px;
}

#player-card .side-menu-card p {
    color: inherit;
    margin-bottom: 0;
    font-size: 18px;
    font-weight: 400;
    line-height: 22px;
}

#player-card .side-menu-card .btns-action {
    display: flex;
    position: absolute;
    bottom: 40px;
    left: 20px;
    right: 20px;
    align-items: center;
    justify-content: space-between;
}

#player-card .side-menu-card .btns-action button {
    /* color: #082835; */
    gap: 5px;
    font-size: 14px;
    font-weight: 400;
    line-height: 17px;
    align-items: center;
    display: flex;
}

#player-card .side-menu-card .warning-actions {
    display: flex;
    justify-content: end;
    width: 100%;
}

#player-card .side-menu-card .warning-actions .btns-action {
    bottom: 20px;
    left: auto;
    right: 20px;
    width: 50%;
}

#player-card .side-menu-card .warning-actions .btns-action button {
    color: #082835;
    font-size: 20px;
    font-weight: 400;
    line-height: 24px;
}

/* end side menu card */
/* DARK MODE */
#player-card.contrast-style,
#player-card.contrast-style #finish-rate,
#player-card.contrast-style #loader-finish-rate,
#player-card.contrast-style .about-card,
#player-card.contrast-style .side-menu-card {
    background-color: #777777;
}
#player-card.contrast-style .talk-content .btns-action,
#player-card.contrast-style .talk-content .btns-config,
#player-card.contrast-style .talk-content .btns-info,
#player-card.contrast-style .talk-content .settings-card,
#player-card.contrast-style .talk-content .tutorial-card,
#player-card.contrast-style .talk-content .change-speed-card {
    background-color: #333333;
}

#player-card.contrast-style .talk-content .btns-action span,
#player-card.contrast-style .talk-content .btns-config span,
#player-card.contrast-style .talk-content .btns-info span,
#player-card.contrast-style .tutorial-card .translate-item .icon,
#player-card.contrast-style .talk-content .settings-card .icon,
#player-card.contrast-style .about-card .icon {
    background-color: #4E4E4E;
}

#player-card.contrast-style .settings-card .settings-item,
#player-card.contrast-style .talk-content .change-speed-card,
#player-card.contrast-style .tutorial-card .translate-item,
#player-card.contrast-style .about-card h3,
#player-card.contrast-style .about-card p,
#player-card.contrast-style .side-menu-card {
    color: #F0F5FA;
}

/* left player */
#player-card.left {
    left: -1000px;
    right: inherit;
}
#player-card.left.player-active {
    left: 0;
}
#player-card.left .side-menu-card {
    left: -1000px;
    transition: left 0.7s ease-out;
}
#player-card.left .side-menu-card.active {
    left: 260px;
}
#player-card.left .side-menu-card::before {
    left: -7px;
}
#player-card.left #set-position img {
    transform: rotateY(180deg);
}
/* end left player */


#player-card .talk-content .btns-action span img {
    max-width: 100%;
}

#player-card .btns-config span img, #player-card .btns-info span img {
    max-width: 100%;
}

#player-card .tutorial-card .translate-item .icon img,
#player-card .talk-content .settings-card .icon img {
    max-width: 100%;
}
#player-card .tamkin-logo {
    max-height: 33px;
}
#player-card .tamkin-logo-bg {
    max-height: 33px;
    background: white;
    border-radius: 50%;
    padding: 6px;
}
#player-card #links-container .tamkin-logo {
    max-height: 25px;
}
/* 
#player-card .text-main {
    color: #35C0B4 !important;
} */

@media(max-width:550px) {
    #player-card {
        width: 200px;
    }
    #player-card .side-menu-card.active {
        right: 200px;
    }
    #player-card.left .side-menu-card.active {
        left: 200px;
    }
    #player-card .side-menu-card{
        width: 170px;
    }
    #player-card .side-menu-card#tutorial-menu{
        width: 180px;
        padding: 10px;
    }
    #player-card .side-menu-card#tutorial-menu .btns-about-bottom{
        left: 10px;
        right: 10px;
    }
    #player-card .talk-content .btns-action span {
        width: 30px;
        height: 30px;
        padding: 7px;
    }

    #player-card .btns-config span, #player-card .btns-info span {
        width: 30px;
        height: 30px;
        padding: 3px;
    }
    #player-card .talk-content .settings-card .settings-item {
        font-size: 10px;
    }
    #player-card .tutorial-card .translate-item .icon,
    #player-card .talk-content .settings-card .icon {
        width: 30px;
        height: 30px;
        padding: 3px;
    }
    #player-card .tutorial-card .translate-item .icon{
        padding: 7px;
    }
}

@media(max-width:300px) {
    #player-card {
        width: 150px;
    }
    #player-card .side-menu-card.active {
        right: 150px;
    }
    #player-card.left .side-menu-card.active {
        left: 150px;
    }
    #player-card .side-menu-card{
        width: 140px;
    }
    #player-card .side-menu-card#tutorial-menu{
        width: 150px;
        padding: 5px;
    }
    #player-card .side-menu-card#tutorial-menu .btns-about-bottom{
        left: 5px;
        right: 5px;
    }
    #player-card .side-menu-card#tutorial-menu .btns-about-bottom img{
        display: none;
    }
    #player-card .talk-content .btns-action span {
        width: 22px;
        height: 22px;
        padding: 7px;
    }

    #player-card .btns-config span, #player-card .btns-info span {
        width: 22px;
        height: 22px;
        padding: 3px;
    }
    #player-card .talk-content .settings-card .settings-item {
        font-size: 10px;
    }
    #player-card .tutorial-card .translate-item .icon,
    #player-card .talk-content .settings-card .icon {
        width: 22px;
        height: 22px;
        padding: 3px;
    }
    #player-card .tutorial-card .translate-item .icon{
        padding: 7px;
    }
}

:root{
    --cursor-text: url("https://tamkin.app/assets/tamkin_website/img/player/Hand translation.png");
    --cursor-non-text: url("https://tamkin.app/assets/tamkin_website/img/player/icon-warning.png");
}
body.player-actived .translatable-tamkin:not(#player-card *){
    cursor: var(--cursor-text), auto !important;
    text-decoration: underline !important;
}
body.player-actived .non-translatable-tamkin:not(#player-card *){
    cursor: var(--cursor-non-text), auto !important;
}
body.player-actived{
    cursor: var(--cursor-non-text), auto;
}
#player-card{
    cursor: default;
}


.action-tooltip {
    display: none;
    position: absolute;
    padding: 5px;
    cursor: pointer;
    display: block;
    background-color: white;
    border: 1px solid #eeeeee;
    box-sizing: border-box;
    border-radius: 8px;
    pointer-events: auto;
    z-index: 10000;
}

.action-tooltip::after {
    content: "";
    position: absolute;
    width: 0;
    height: 0;
    left: 12%;
    top: 0;
    box-sizing: border-box;
    border: 8px solid white;
    border-color: white white transparent transparent;
    transform-origin: 0 0;
    transform: rotate(-45deg);
    box-shadow: 1px -1px 0 0 #eeeeee;
}

